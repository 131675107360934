<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title v-if="tariff.id" class="headline grey lighten-2" primary-title>Редактировать тариф</v-card-title>
      <v-card-title v-else class="headline grey lighten-2" primary-title>Добавить тариф</v-card-title>
      <v-card-text>
        <v-text-field v-model="tariffName" box label="Наименование" required></v-text-field>
        <v-layout>
          <v-flex md6>
            <input v-show="false" ref="file" type="file" accept=".xlsx, .xls" @change="addFile($event)" />
            <v-text-field v-model="fileName" box label="Файл" disabled></v-text-field>
          </v-flex>
          <v-flex v-if="tariff.file_path" md1>
            <v-btn icon ripple @click.stop="download()" class="tw-flex-none">
              <v-icon color="blue">download</v-icon>
            </v-btn>
          </v-flex>
          <v-flex md5>
            <v-btn color="info" @click="openFileDialog">Загрузить тариф (.xls)</v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="info" outline @click="closeDialog()">Закрыть</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="tariff.id" color="error" @click="deleteTariff()">Удалить</v-btn>
        <v-btn color="success" @click="saveTariff()">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { downloadFile } from '@/utils/downloadFile'

export default {
  props: {
    value: Boolean,
    tariff: Object,
  },

  data: () => ({
    file: null,
    tariffName: '',
  }),

  watch: {
    tariff(newVal) {
      this.tariffName = newVal.name
    },
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    fileName: {
      get() {
        if (this.file) {
          return this.file.name
        }
        if (this.tariff.file_path) {
          return this.tariff.file_path.split('/').slice(-1)[0]
        }
        return ''
      },
    },
  },

  methods: {
    async saveTariff() {
      const formData = new FormData()
      formData.append('name', this.tariffName)
      if (this.file) {
        formData.append('file', this.file, this.file.name)
      }

      if (this.tariff.id) {
        await this.axios.post(`/api/admin/delivery/tariffs/${this.tariff.id}`, formData)
      } else {
        await this.axios.post('/api/admin/delivery/tariffs', formData)
      }

      this.closeDialog()
      this.$emit('update')
    },

    async deleteTariff() {
      await this.axios.delete(`/api/admin/delivery/tariffs/${this.tariff.id}`)

      this.closeDialog()
      this.$emit('update')
    },

    openFileDialog() {
      this.$refs['file'].value = ''
      this.$refs['file'].click()
    },

    async addFile(event) {
      this.file = event.target.files[0]
    },

    closeDialog() {
      this.file = null
      this.$emit('input', false)
    },

    async download() {
      downloadFile(`/api/admin/delivery/tariffs/${this.tariff.id}/file`, this.fileName)
    },
  },
}
</script>
