<template>
  <v-container fluid>
    <tariff-dialog v-model="dialogTariff" :tariff="tariffForEditing" @update="loadTariffs()" />

    <div class="information-page">
      <div class="headline mb-4">Информация</div>

      <v-tabs v-if="pages.length > 0" color="gray" light slider-color="black">
        <v-tab v-for="(page, index) in pages" :key="index">{{ page.title }}</v-tab>
        <v-tab>Контакты</v-tab>
        <v-tab>Тарифы</v-tab>
        <v-tab-item v-for="(page, index) in pages" :key="page.id" class="pt-4">
          <v-form>
            <v-card v-for="(block, blockIndex) in page.blocks" :key="'block' + block.id" class="mb-4">
              <v-card-text>
                <v-textarea
                  box
                  :error-messages="error(block, 'title')"
                  label="Название блока"
                  v-model="block.title"
                ></v-textarea>
                <v-textarea
                  box
                  :error-messages="error(block, 'text')"
                  label="Текст блока"
                  v-model="block.text"
                ></v-textarea>
              </v-card-text>

              <v-card-actions>
                <v-btn flat @click="deleteBlock(index, blockIndex)" color="error">Удалить блок</v-btn>
              </v-card-actions>
            </v-card>

            <v-btn block @click="addBlock(index)" color="info" class="mb-4">Добавить блок</v-btn>

            <v-card class="mb-4">
              <v-card-text>
                <v-textarea
                  box
                  :error-messages="error(page, 'text')"
                  label="Текст страницы"
                  v-model="page.text"
                  hint="Вводите текст"
                ></v-textarea>
                <v-checkbox label="Отображать" v-model="page.visible" id="visible"></v-checkbox>
              </v-card-text>
            </v-card>
            <v-btn block @click="savePage(index)" color="success">Сохранить</v-btn>
          </v-form>
        </v-tab-item>
        <v-tab-item class="contacts-tab">
          <v-form class="d-flex contacts-tab-form">
            <div class="pa-2 contacts-col">
              <div class="contacts-info">
                <v-container grid-list-lg>
                  <div class="title">Телефоны</div>
                  <v-layout v-for="(phone, index) in contacts.phones" :key="index" row>
                    <v-flex xs3>
                      <v-text-field v-model="phone.phone" label="Телефон"></v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-select v-model="phone.type" :items="CONTACT_TYPE" label="Тип" required></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field v-model="phone.description" label="Описание"></v-text-field>
                    </v-flex>
                    <v-flex xs1 class="align-center">
                      <v-btn flat icon small @click="removePhone(index)" color="error">
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-btn @click="addPhone" color="info" class="add-btn ml-0">Добавить</v-btn>
                </v-container>
                <v-container grid-list-lg>
                  <div class="title">Адреса электронной почты</div>
                  <v-layout v-for="(email, index) in contacts.emails" :key="index">
                    <v-flex xs3>
                      <v-text-field v-model="email.email" label="Email"></v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-select v-model="email.type" :items="CONTACT_TYPE" label="Тип" required></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field v-model="email.description" label="Описание"></v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-btn flat icon small @click="removeEmail(index)" color="error">
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-btn @click="addEmail" color="info" class="add-btn ml-0">Добавить</v-btn>
                </v-container>
                <v-container grid-list-lg>
                  <div class="title">Адреса магазинов</div>
                  <v-layout v-for="(shop, index) in shops" :key="index">
                    <v-flex xs3>
                      <v-text-field v-model="shop.address" label="Адрес" />
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field v-model="shop.working_hours" label="Время работы" />
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field v-model="shop.phone" label="Телефон" />
                    </v-flex>
                    <v-flex xs1>
                      <v-btn flat icon small @click="removeShop(index)" color="error">
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-btn @click="addShop" color="info" class="add-btn ml-0">Добавить</v-btn>
                </v-container>
                <v-container grid-list-lg>
                  <div class="title">Социальные сети</div>
                  <v-layout>
                    <v-flex xs10>
                      <v-text-field
                        label="Facebook"
                        v-model="contacts.social.facebook.link"
                        class="new-input-230"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-switch v-model="contacts.social.facebook.status"></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs10>
                      <v-text-field
                        label="Telegram"
                        v-model="contacts.social.telegram.link"
                        class="new-input-230"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-switch v-model="contacts.social.telegram.status"></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs10>
                      <v-text-field
                        label="VK"
                        v-model="contacts.social.vkontakte.link"
                        class="new-input-230"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-switch v-model="contacts.social.vkontakte.status"></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs10>
                      <v-text-field
                        label="Instagram"
                        v-model="contacts.social.instagram.link"
                        class="new-input-230"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-switch v-model="contacts.social.instagram.status"></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </div>

            <div class="pa-2 mt-4 contacts-col">
              <div>
                <v-text-field v-model="contacts.work_time" label="Рабочее время"></v-text-field>
              </div>

              <div>
                <v-text-field v-model="contacts.address" label="Пункт самовывоза"></v-text-field>
              </div>

              <div>
                <v-text-field v-model="contacts.map" label="Карта"></v-text-field>
              </div>

              <div>
                <v-btn @click="saveContacts" color="success" class="ml-0">Сохранить</v-btn>
              </div>
            </div>
          </v-form>
        </v-tab-item>
        <v-tab-item>
          <v-container grid-list-lg fluid>
            <v-layout v-for="(city, index) in cities" :key="index">
              <v-flex xs3>
                <v-text-field v-model="city.name" label="Зона доставки" required></v-text-field>
              </v-flex>

              <v-flex xs3>
                <v-select v-model="city.type" :items="DELIVERY_TYPE" label="Способ доставки" required></v-select>
              </v-flex>

              <v-flex v-if="city.type === 'transport_company'" xs2>
                <v-select
                  v-model="city.delivery_tariff_id"
                  :items="tariffs"
                  item-text="name"
                  item-value="id"
                  label="Стоимость"
                  :menu-props="{ closeOnContentClick: true }"
                  required
                >
                  <template #item="{ item }">
                    <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
                      <div>{{ item.name }}</div>
                      <v-btn icon ripple @click.stop="editTariff(item)" class="tw-flex-none">
                        <v-icon color="blue">edit</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template #append-item>
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-btn @click="addTariff()" fab small color="primary" dark>
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-list-tile-avatar>
                    </v-list-tile>
                  </template>
                </v-select>
              </v-flex>

              <v-flex v-else xs2>
                <v-text-field v-model="city.price" label="Стоимость" required></v-text-field>
              </v-flex>

              <v-flex xs3>
                <v-text-field
                  v-model="city.freeShippingFrom"
                  :label="`Бесплатно при сумме заказа ${CURRENCY_SYMBOL}`"
                ></v-text-field>
              </v-flex>

              <v-flex xs1>
                <v-btn icon ripple @click="removeCity(index)">
                  <v-icon color="red">clear</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex shrink>
                <v-btn @click="addCity()" color="info" class="ml-0">Добавить зону доставки</v-btn>
              </v-flex>
              <v-flex shrink>
                <v-btn @click="saveCities" class="ml-0" color="success">Сохранить</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-container>
</template>

<script>
import TariffDialog from '@/components/dialogs/TariffDialog'
import { CURRENCY_SYMBOL } from '@/constants.js'

const CONTACT_TYPE = [
  { text: 'Основной', value: 'main' },
  { text: 'Постпродажи', value: 'post_sale' },
]

const DELIVERY_TYPE = [
  { text: 'Свой курьер', value: 'own_courier' },
  { text: 'Транспортная компания', value: 'transport_company' },
  { text: 'Самовывоз', value: 'pickup' },
]

export default {
  components: { TariffDialog },

  data() {
    return {
      CURRENCY_SYMBOL,
      pages: [],
      cities: [],
      tariffs: [],
      shops: [],
      contacts: {
        phones: [],
        emails: [],
        social: {
          facebook: {
            link: '',
            status: false,
          },
          telegram: {
            link: '',
            status: false,
          },
          vkontakte: {
            link: '',
            status: false,
          },
          instagram: {
            link: '',
            status: false,
          },
        },
        vibers: [],
        address: '',
        work_time: '',
        map: '',
      },
      CONTACT_TYPE,
      DELIVERY_TYPE,
      dialogTariff: false,
      tariffForEditing: {},
    }
  },

  mounted() {
    this.loadContacts()
    this.loadCities()
    this.loadPages()
    this.loadTariffs()
  },

  methods: {
    addPhone() {
      this.contacts.phones.push({ phone: '', type: 'main', description: '' })
    },

    addEmail() {
      this.contacts.emails.push({ email: '', type: 'main', description: '' })
    },

    addShop() {
      this.shops.push({ address: '', working_hours: '', phone: '' })
    },

    addCity() {
      this.cities.push({ name: '', type: 'own_courier', price: '', freeShippingFrom: '' })
    },

    removePhone(index) {
      this.contacts.phones = this.contacts.phones.filter((item, pos) => pos != index)
    },

    removeEmail(index) {
      this.contacts.emails = this.contacts.emails.filter((item, pos) => pos != index)
    },

    removeShop(index) {
      this.shops.splice(index, 1)
    },

    removeCity(index) {
      this.cities = this.cities.filter((item, pos) => pos != index)
    },

    saveContacts() {
      this.axios.post('/api/settings/contacts', {
        value: {
          phones: this.contacts.phones,
          emails: this.contacts.emails,
          shops: this.shops,
          social: this.contacts.social,
          vibers: this.contacts.vibers,
          address: this.contacts.address,
          work_time: this.contacts.work_time,
          map: this.contacts.map,
        },
      })
    },

    saveCities() {
      this.axios.post('/api/settings/cities', { value: this.cities })
    },

    async loadContacts() {
      const response = await this.axios.get('/api/settings/contacts')
      this.contacts = response.data.value
      this.shops = response.data.value.shops ? response.data.value.shops : []
    },

    async loadCities() {
      const response = await this.axios.get('/api/settings/cities')
      this.cities = response.data.value
    },

    async loadTariffs() {
      const response = await this.axios.get('/api/admin/delivery/tariffs')
      this.tariffs = response.data.data
    },

    editTariff(tariff) {
      this.dialogTariff = true
      this.tariffForEditing = tariff
    },

    addTariff() {
      this.dialogTariff = true
      this.tariffForEditing = {}
    },

    loadPages() {
      this.axios.get('/api/pages').then((response) => {
        this.pages = response.data
      })
    },

    addBlock(index) {
      this.pages[index].blocks.push({
        id: null,
        title: '',
        text: '',
      })
    },

    deleteBlock(index, blockIndex) {
      this.pages[index].blocks.splice(blockIndex, 1)
    },

    savePage(index) {
      this.pages[index].errors = false
      this.axios.post('/api/pages', { ...this.pages[index] }).then((response) => {
        if (!response.data.success) {
          this.pages[index].errors = response.data.errors
        }
      })
    },

    error(target, field) {
      return target.errors && target.errors[field] ? target.errors[field] : []
    },
  },
}
</script>
